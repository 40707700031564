import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('journey', {
    url: '/journey',
    templateProvider: () =>
      import(/* webpackChunkName: "alr" */ './journey.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'journeyCtrl as vm',
    requiredPermission: 'journey',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "journey" */ './journey.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/rome'),
          import('../../extensions/directives/validator-date'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      gpsRealtimeV: GpsRealtimeV =>
        GpsRealtimeV.find({ filter: {
          order: 'localName ASC',
          fields: {
            eventTime: true,
            effectEndDate: true,
            latitude: true,
            localName: true,
            longitude: true,
            transportId: true,
          },
        } }).$promise,
      gpsCustomerFeedbackType: GpsCustomerFeedbackType => GpsCustomerFeedbackType.find().$promise,
    },
  });
}

export default angular.module('app.journey.routing', []).config(routes);
