import angular from 'angular';

class LoginCtrl {
  constructor(AuthService, $scope, GpsCustomers) {
    'ngInject';

    this.$scope = $scope;
    this.AuthService = AuthService;
    this.GpsCustomers = GpsCustomers;

    this.credentials = {
      username: '',
      password: '',
    };
    this.rememberMe = false;
    this.errorMessage = '';
    $('body').css('background-color', '#D9EEF9');
    this.forgetPass = () => {
      this.content = 'Xin vui lòng liên hệ tổng đài: 0907 049 049 để được hỗ trợ';
    };
  }

  login(credentials, rememberMe) {
    this.errorMessage = null;
    credentials.username = credentials.username.toLowerCase();
    this.AuthService.login(credentials, rememberMe)
      .then((user) => {
        if (this.$scope.$parent.app) {
          this.$scope.$parent.app.setCurrentUser(user);
        }
      })
      .catch((err) => {
        if (err && err.data && err.data.error) {
          this.errorMessage = 'Sai tài khoản đăng nhập';
        }
      });
    // this.GpsCustomers.checkUser({ credentials }).$promise.then((data) => {
    //   if (data.status === 1) {
    //     this.AuthService.login(credentials, rememberMe)
    //       .then((user) => {
    //         if (this.$scope.$parent.app) {
    //           this.$scope.$parent.app.setCurrentUser(user);
    //         }
    //       })
    //       .catch((err) => {
    //         if (err && err.data && err.data.error) {
    //           this.errorMessage = err.data.error.message;
    //         }
    //       });
    //   } else if (data.status === 0) {
    //     $('.alert').show();
    //     $('.alert').delay(4000).slideUp(200, () => {
    //       $(this).alert('close');
    //     });
    //     this.errorMessage = 'Tài khoản đã bị khóa';
    //   } else {
    //     $('.alert').show();
    //     $('.alert').delay(4000).slideUp(200, () => {
    //       $(this).alert('close');
    //     });
    //     this.errorMessage = 'Tài khoản đăng nhập sai';
    //   }
    // });
  }
}

export default angular
  .module('login.controller', [])
  .controller('LoginCtrl', LoginCtrl);
