import angular from 'angular';

function getAccessToken($transition$, User, $window) {
  'ngInject';

  const params = $transition$.params();
  if (!params.accessToken) return;
  User.checkAccessToken({ accessToken: params.accessToken }).$promise.then((data) => {
    sessionStorage.setItem('$LoopBack$accessTokenId', data.accessToken);
    sessionStorage.setItem('$LoopBack$currentUserId', data.id);
    sessionStorage.setItem('$LoopBack$rememberMe', data.save);
    $window.location.reload();
  });
  return true;
}

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('login', {
      url: '/login?accessToken',
      params: {
        accessToken: { type: 'string', dynamic: true },
      },
      template: require('./login.jade'),
      controller: 'LoginCtrl',
      controllerAs: 'login',
      resolve: {
        getAccessToken,
      },
    });
}

export default angular
  .module('login.routing', [
    require('./login.controller').name,
  ])
  .config(routes);
