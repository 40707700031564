/* eslint class-methods-use-this: 1 */
/* eslint-env es6 */

import angular from 'angular';

import fmChangePasswordTemplate from './change-password.pug';

class ApplicationCtrl {
  constructor(
    $sce,
    $timeout,
    $window,
    $interval,
    $rootScope,
    AuthService,
    AuthEvents,
    $templateCache,
    alertify,
    Messages,
    Config,
  ) {
    'ngInject';

    this.AuthService = AuthService;
    this.$interval = $interval;
    this.alertify = alertify;
    this.Messages = Messages;
    this.$window = $window;

    $templateCache.put('change-password', fmChangePasswordTemplate);
    $rootScope.$on(
      AuthEvents.currentUserUpdated,
      async (event, currentUser) => {
        this.currentUser = currentUser;
        this.user = this.currentUser;
        this.menu = [
          { state: 'monitor', title: 'Giám Sát', iconClass: 'fa-search' },
          { state: 'journey', title: 'Hành Trình', iconClass: 'fa-road' },
          { state: 'standard', title: 'Báo cáo', iconClass: 'fa-file-text-o' },
          {
            state: 'password',
            title: 'Cập nhật',
            iconClass: 'fa-cogs',
          },
        ];
        this.username = this.currentUser.username;
      },
    );
    const listKey = Config.get('googleMapAPIKey');
    const key = listKey[Math.floor(Math.random() * listKey.length)];
    this.googleMapApi = $sce.trustAsResourceUrl(
      [
        'https://maps.googleapis.com/maps/api/js?key=',
        key,
        '&libraries=places',
      ].join(''),
    );

    const onresize = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      $timeout(() => {
        this.size = {
          width,
          height,
          bodyHeight: height - 150,
          gridHeight: Math.max(height - 150, 500),
        };
      });
    };
    window.onresize = onresize;
    onresize();
  }

  setCurrentUser(currentUser) {
    this.currentUser = currentUser;
  }

  logout() {
    this.AuthService.logout();
    // this.$window.location.reload();
  }

  mobile() {
    document.getElementById('navbar-mobile').style.backgroundColor = '#00B73A';
  }

  isAuthenticated() {
    return this.AuthService.isAuthenticated();
  }
}

export default angular
  .module('app.application.controller', [])
  .controller('ApplicationCtrl', ApplicationCtrl);
